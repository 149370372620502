<template>
  <div class="claim-request-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link to="/admin/affiliate-management/token-payout">
              <CIcon name="cil-arrow-left" class="mr-1" />Back to Token Payout
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody @change="isFormChange=true">
            <ValidationObserver ref="form" v-if="!isLoading" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_NUMBER')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{claimRequestDetails.id}}</span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_REQUESTED')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{claimRequestDetails.createdAt | moment(dateFormat)}}</span>
                    </div>
                  </CCol>
                </CRow>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_EMAIL')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <span>{{claimRequestDetails.Member.email}}</span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_STATUS')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <div class="status">
                        <StakingBadge
                          v-if="claimRequestDetails.status=='Payout pending'"
                          class="no-border"
                          type="claim-request-status"
                          :text="claimRequestDetails.status"
                        />
                        <StakingBadge
                          v-show="!toggleEditDate"
                          v-else
                          class="no-border"
                          type="claim-request-status"
                          :text="claimRequestDetails.status"
                          :description="datePayoutTransferred"
                        />
                      </div>
                      <div
                        v-show="toggleEditDate"
                        v-if="isAllowedUpdatePayoutTransferred &&claimRequestDetails.status!='Payout pending'"
                        class="status"
                      >
                        <datetime
                          type="datetime"
                          v-model="originalDateReferences"
                          :format="dateTimePickerFormat"
                          input-class="form-control"
                          @focus="handleFocusDateReferences"
                        ></datetime>
                      </div>
                      <span
                        class="status ml-2"
                        v-if="isAllowedUpdatePayoutTransferred &&claimRequestDetails.status!='Payout pending'"
                      >
                        <a v-show="!toggleEditDate" @click="editDate()" href="#">{{editTitle}}</a>
                      </span>
                      <div class="group-status-references status">
                        <div v-if="isDateReferencesFocus" class="float-buttons-container">
                          <div class="referrer-actions">
                            <CButton class="referrer-submit" @click="submitDateReferences">
                              <CIcon name="cil-check-alt" />
                            </CButton>
                            <CButton @click="cancelDateReferences">
                              <CIcon name="cil-x" />
                            </CButton>
                          </div>
                        </div>
                        <ValidationProvider v-slot="{ errors }" ref="dateReferences">
                          <CRow v-if="errMsg || errors[0]">
                            <CCol>
                              <p class="error-msg">{{errors[0] || errMsg}}</p>
                            </CCol>
                          </CRow>
                        </ValidationProvider>
                      </div>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_PAYMENT_TYPE')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext" v-if="claimRequestDetails.type === 'Bank'">
                      <CIcon name="cil-bank" class="mr-2" />
                      <span class="payment-type">{{claimRequestDetails.type}}</span>
                    </div>

                    <div
                      class="form-control-plaintext"
                      v-if="claimRequestDetails.type === 'Crypto'"
                    >
                      <img
                        v-if="claimRequestDetails.currency_symbol.toUpperCase() === 'BTC'"
                        src="@/assets/images/currencies/btc.png"
                        alt="BTC"
                        class="currency-icon mr-2"
                      />

                      <img
                        v-if="claimRequestDetails.currency_symbol.toUpperCase() === 'ETH'"
                        src="@/assets/images/currencies/eth.png"
                        alt="ETH"
                        class="currency-icon mr-2"
                      />

                      <img
                        v-if="claimRequestDetails.currency_symbol.toUpperCase() === 'USDT'"
                        src="@/assets/images/currencies/erc-20-default.png"
                        alt="USDT"
                        class="currency-icon mr-2"
                      />

                      <span class="payment-type">{{claimRequestDetails.currency_symbol}}</span>
                    </div>
                  </CCol>
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_AMOUNT')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingCurrency
                        :value="claimRequestDetails.amount"
                        :currencySymbol="claimRequestDetails.currency_symbol"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="claimRequestDetails.type === 'Bank'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_BANK_ACCOUNT_NO')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <input
                      type="text"
                      v-model="claimRequestDetails.account_number"
                      disabled="disabled"
                      class="form-control"
                    />
                  </CCol>
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_BANK_NAME')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <input
                      type="text"
                      v-model="claimRequestDetails.bank_name"
                      disabled="disabled"
                      class="form-control"
                    />
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="claimRequestDetails.type == 'Bank'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_SWIFT')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <input
                      type="text"
                      v-model="claimRequestDetails.swift"
                      disabled="disabled"
                      class="form-control"
                    />
                  </CCol>
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_ACCOUNT_HOLDER')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <input
                      type="text"
                      v-model="claimRequestDetails.account_holder"
                      disabled="disabled"
                      class="form-control"
                    />
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="claimRequestDetails.type === 'Crypto'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_RECEIVE_ADDRESS')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingExplorerLink
                        type="address"
                        :currencySymbol="claimRequestDetails.currency_symbol"
                        :value="claimRequestDetails.wallet_address"
                      />
                    </div>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_NETWORK_FEE')}}</label>
                  </CCol>
                  <CCol lg="4" md="4">
                    <div class="form-control-plaintext">
                      <StakingCurrency
                        :value="claimRequestDetails.network_fee"
                        :currencySymbol="claimRequestDetails.currency_symbol"
                      />
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group" v-if="claimRequestDetails.type === 'Crypto'">
                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_LABEL_TRANSACTION_ID')}}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext" v-if="claimRequestDetails.txid">
                      <StakingExplorerLink
                        type="tx"
                        :currencySymbol="claimRequestDetails.currency_symbol"
                        :value="claimRequestDetails.txid"
                      />
                    </div>
                    <div v-if="isAllowedUpdatingTxId">
                      <ValidationProvider
                        name="TOKEN_PAYOUT_DETAILS_COMPONENT_TRANSACTION_ID_FIELD_NAME"
                        rules="max:500"
                      >
                        <div slot-scope="{ errors }">
                          <input
                            type="text"
                            v-model="claimRequestDetails.newTxId"
                            class="form-control"
                          />
                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </div>
                      </ValidationProvider>
                    </div>
                  </CCol>
                </CRow>

                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{ $t('TOKEN_PAYOUT_DETAILS_COMPONENT_TRANSACTION_NOTES_FIELD_NAME') }}</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <ValidationProvider name="TOKEN_PAYOUT_DETAILS_COMPONENT_TRANSACTION_NOTES_LABEL" rules="max:2000">
                      <div slot-scope="{ errors }">
                        <textarea v-model="claimRequestDetails.notes" rows="5" class="form-control"></textarea>
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{ errors[0] }}</span>
                        </span>
                      </div>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      @click="openWarningLeaveModal"
                      class="btn btn-secondary btn-cancel"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_BUTTON_CANCEL')}}</CButton>

                    <CButton
                      @click="onSubmit()"
                      v-if="isAllowedUpdatingTxId"
                      color="primary"
                      class="btn btn-update"
                    >{{$t('TOKEN_PAYOUT_DETAILS_COMPONENT_BUTTON_UPDATE')}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>

            <StakingModal
              :linkTo="linkTo"
              :warningLeavePage="warningLeavePage"
              @close-modal="closeModal"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import StakingExplorerLink from '@/components/StakingExplorerLink';
import StakingCurrency from '@/components/StakingCurrency';
import StakingBadge from '@/components/StakingBadge';
import { MembershipOrderStatus } from '@/enums';
import { ClaimRequestStatus } from '@/enums';
import { Constants, env } from '@/constants';
import StakingModal from '@/components/StakingModal/index';

export default {
  name: 'TokenPayoutDetails',
  components: {
    VclList,
    StakingExplorerLink,
    StakingCurrency,
    StakingBadge,
    StakingModal,
  },
  data() {
    return {
      claimRequestDetails: null,
      isLoading: true,
      options: [],
      isAllowedUpdatingTxId: false,
      dateFormat: Constants.dateFormat,
      dateTimePickerFormat: Constants.dateTimePickerFormat,
      warningLeavePage: false,
      isFormChange: false,
      linkTo: '/admin/affiliate-management/token-payout',
      isDateReferencesFocus: false,
      errMsg: '',
      dateReferences: null,
      originalDateReferences: null,
      isAllowedUpdatePayoutTransferred: false,
      toggleEditDate: false,
      editTitle: this.$t('TOKEN_PAYOUT_DETAILS_COMPONENT_BUTTON_EDIT'),
      datePayoutTransferred: '',
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const TokenPayoutId = this.$route.params.TokenPayoutId;
    this.checkPermissions();

    this.getClaimRequestDetails(TokenPayoutId);
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'AFFILIATE_TOKEN_PAYOUT_UPDATE_TX_ID') {
            this.isAllowedUpdatingTxId = true;
          }
          if (item.name === 'AFFILIATE_UPDATE_PAYOUT_TRANSFERRED') {
            this.isAllowedUpdatePayoutTransferred = true;
          }
        });
      }
    },
    async getClaimRequestDetails(TokenPayoutId) {
      try {
        const res = await this.$http.get(endpoints.getTokenPayoutDetails(TokenPayoutId));
        this.claimRequestDetails = res.data;
        this.isAllowedUpdatingTxId = this.isAllowedUpdatingTxId && this.claimRequestDetails.type === 'Crypto';
        this.claimRequestDetails.status = ClaimRequestStatus[this.claimRequestDetails.status];
        this.claimRequestDetails.id = stringHelper.padDigits(this.claimRequestDetails.id, 4);
        this.claimRequestDetails.newTxId = this.claimRequestDetails.txid;
        this.originalDateReferences = this.claimRequestDetails.payout_transferred;
        this.datePayoutTransferred = `[${moment(this.claimRequestDetails.payout_transferred).format(Constants.dateFormat)}]`;
        this.isLoading = false;

        this.claimRequestDetails.network_fee = this.claimRequestDetails.network_fee
        ? this.claimRequestDetails.network_fee : 0;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOKEN_PAYOUT_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      this.updateTxId();
    },
    async updateTxId() {
      if (this.claimRequestDetails.isProcessing) {
        return;
      }

      this.claimRequestDetails.isProcessing = true;

      try {
        const data = {
          txid: this.claimRequestDetails.newTxId,
          notes: this.claimRequestDetails.notes,
        };
        const result = await this.$http.put(endpoints.updateTxIdTokenPayout(this.claimRequestDetails.id), data);

        this.claimRequestDetails.txid = this.claimRequestDetails.newTxId;
        this.claimRequestDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('TOKEN_PAYOUT_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('TOKEN_PAYOUT_DETAILS_PAGE_NOTIFY_SUCCESS_MESSAGE'),
        });
      } catch (err) {
        this.claimRequestDetails.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOKEN_PAYOUT_DETAILS_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    openWarningLeaveModal() {
      if (this.isFormChange) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.linkTo);
      }
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    async submitDateReferences() {
      const isValid = await this.$refs.dateReferences.validate();
      if (!isValid.valid) {
        return;
      }
      try {
        const params = { payoutTransferred: moment(this.originalDateReferences)};
        const res = await this.$http.put(endpoints.updatePayoutTransferred(this.claimRequestDetails.id), params);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('TOKEN_PAYOUT_DETAILS_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('TOKEN_PAYOUT_DETAILS_PAGE_NOTIFY_SUCCESS_MESSAGE'),
        });

        this.datePayoutTransferred = `[${moment(this.originalDateReferences).format(Constants.dateFormat)}]`;
        this.isDateReferencesFocus = false;
        this.toggleEditDate = false;
        await this.$refs.dateReferences.validate();

        this.errMsg = '';
      } catch (err) {
        this.errMsg = responseHelper.getErrorMessage(err);
      }
    },
    handleFocusDateReferences() {
      this.isDateReferencesFocus = true;
    },
    cancelDateReferences() {
      this.originalDateReferences = this.claimRequestDetails.payout_transferred;
      this.isDateReferencesFocus = false;
      this.errMsg = '';
      this.toggleEditDate = false;
    },
    editDate() {
      this.isDateReferencesFocus = true;
      this.toggleEditDate = true;
    },
  },
};
</script>
<style lang="scss">
.claim-request-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    .group-status-references {
      position: relative;
      display: flex;
      flex: 0 0 100%;
      .float-buttons-container {
        position: relative;
        .referrer-actions {
          position: absolute;
          left: calc(41.6667% - 80px);
          top: 40px;
          z-index: 99;
          button {
            width: 35px;
            height: 35px;
            background: #f5f6f8;
            color: #3a4a7f;
            box-shadow: -2px 2px 5px -2px #00000080;
            padding: 0;
            &.referrer-submit {
              margin-right: 10px;
            }
          }
        }
      }
      .error-msg {
        font-size: 14px;
        font-weight: 300;
        color: #ff0000;
        line-height: 20px;
        margin-left: 10%;
        margin-bottom: 10px;
        padding: 0;
      }
      span {
        width: 100%;
        .form-group {
          display: flex;
          flex: 0 0 100%;
          width: 100%;
          margin-bottom: 0;
        }
      }
      .col-sm-10 {
        padding-left: 10px;
        input {
          width: 30%;
        }
      }
    }
    .status {
      float: left;
    }
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .explorer-link {
      display: inline-block;
    }

    .currency-icon {
      width: 20px;
      height: 20px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      label {
        width: 100%;
        text-align: right;
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        min-width: 145px;
        height: 35px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }
  }
}
</style>
